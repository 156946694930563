import Vue from 'vue'
import VueRouter from 'vue-router'
import util from "@/common/util";
import api from "@/api/api";
import http from "@/common/http";
import {Message} from "element-ui";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: '首页' },
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/MyPage',
    name: 'MyPage',
    meta: { title: '个人信息' },
    component: () => import('@/views/MyPage.vue')
  },
  {
    path: '/MachineKnowledge',
    name: 'MachineKnowledge',
    meta: { title: '机械设计手册' },
    component: () => import('@/views/MachineKnowledge.vue')
  },
  {
    path: '/MachineTool',
    name: 'MachineTool',
    meta: { title: '机械计算工具' },
    component: () => import('@/views/MachineTool.vue')
  },
  {
    path: '/FormulaCustomize',
    name: 'FormulaCustomize',
    meta: { title: '公式定制' },
    component: () => import('@/views/FormulaCustomize.vue')
  },
  {
    path: '/FormulaCustomizeScreen',
    name: 'FormulaCustomizeScreen',
    meta: { title: '公式定制' },
    component: () => import('@/views/FormulaCustomizeScreen.vue')
  },
  {
    path: '/MyFormula',
    name: 'MyFormula',
    meta: { title: '我的公式' },
    component: () => import('@/views/MyFormula.vue')
  },
  {
    path: '/MyDesign',
    name: 'MyDesign',
    meta: { title: '我的设计库' },
    component: () => import('@/views/MyDesign.vue')
  },
  {
    path: '/Teach',
    name: 'Teach',
    meta: { title: '教程' },
    component: () => import('@/views/Teach.vue')
  },
  {
    path: '/About',
    name: 'About',
    meta: { title: '关于' },
    component: () => import('@/views/AboutPage.vue')
  },
  {
    path: '/AIConversation',
    name: 'AIConversation',
    meta: { title: '互动问答' },
    component: () => import('@/views/AIConversation.vue')
  },
  {
    path: '/FormulaMarket',
    name: 'FormulaMarket',
    meta: { title: '方案市场' },
    component: () => import('@/views/FormulaMarket.vue')
  },
  {
    path: '/test',
    name: 'test',
    meta: { title: 'test' },
    component: () => import('@/views/test.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {

  Vue.prototype.$knowledgeAI.changeBtnVisible(to.name != 'AIConversation')

  //获取通知
  // let noticeRes = await http(api.getNotice, {noticeType: 1})
  // if(noticeRes.code == 200) {
  //   store.state.notice = noticeRes.rows
  // }


  //判断页面是否要登录
  // if(to.meta.isLogin && !localStorage.getItem('token')) {
  //   let res = await http(api.getLoginUrl)
  //   if(res.code == 200) {
  //     if(to.name) {
  //       window.location.replace(`${res.msg}${window.location.origin}?redirect=${to.path}`)
  //     }else {
  //       window.location.replace(`${res.msg}${window.location.origin}/`)
  //     }
  //
  //   }
  // }

  if(location.search.indexOf('token=') > -1) {
    to.query.token = util.getQueryObject(location.search).token
    to.query.redirect = util.getQueryObject(location.search).redirect
  }

  if(to.query.token) {
    localStorage.setItem("token", to.query.token);
    let resUserInfo = await http(api.getUserInfo);
    if (resUserInfo.code == 200) {
      resUserInfo.data.isOAuth = true
      Message.success({ message: "登录成功", customClass: "big-message" });
      localStorage.setItem("userInfo", JSON.stringify(resUserInfo.data));
      store.state.permission = {};
      location.href = `${location.origin}/#`
      // if (to.query.redirect) {
      //   Message.success({
      //     message: "正在进行跳转",
      //     customClass: "big-message",
      //   });
      //   location.href = `${location.origin}/#${to.query.redirect}`;
      //   return;
      // }
    } else {
      Message.warning({
        message: "获取用户信息失败",
        customClass: "big-message",
      });
    }
  }else {
    if(localStorage.getItem('token') && localStorage.getItem('userInfo')) {
      store.state.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    }
    next()
  }

})

export default router
