import qs from 'query-string'
import cookie from 'js-cookie'
import template from 'lodash/template'

// 内置数据仓库
if (!window.stringParseStore) {
  window.stringParseStore = {
    url: qs.parse(location.search),
    localStorage: localStorage
  }
}
// 这里重写
let store = {}
Object.defineProperty(store, 'cookie', {
  get: () => cookie.get(),
  configurable: false
})
// 占位符
var interpolate = /{([\s\S]+?)}/

// 编译器缓存
var compilers = {}

// 解析器
var parse = function parse (str, store) {
  if (!compilers[str]) {
    compilers[str] = template(str, { interpolate })
  }
  return compilers[str](store)
}

export default function parseString (string, context) {
  // 上下文不允许包含内置关键字url,cookie
  Object.assign(window.stringParseStore, context)
  Object.assign(store, window.stringParseStore)
  // 解析类型判断
  var getParse = function (string) {
    if (typeof string === 'string') {
      return parse(string, store)
    }
    if (Array.isArray(string)) {
      return string.map(str => getParse(str, store))
    }
    if (Object.prototype.toString.call(string) === '[object Object]') {
      let cache = {}
      for (let key in string) {
        cache[key] = getParse(string[key], store)
      }
      return cache
    }
    return string
  }
  return getParse(string)
}
