const util = {}
/**
 * @module isEmpty
 * @namespace  isEmpty 判断某个值是否为空
 * @author huangpx 判断空值
 * @param  {} keys 某个值 单个
 */
/* eslint-disable */
util.isEmpty = (keys) => {
  if (typeof keys === 'string') {
    keys = keys.replace(/\"|&nbsp;|\\/g, '').replace(/(^\s*)|(\s*$)/g, '')
    if (keys === '' || keys === null || keys === 'null' || keys === 'undefined') {
      return true
    } else {
      return false
    }
  } else if (typeof keys === 'undefined') { // 未定义
    return true
  } else if (typeof keys === 'number') {
    return false
  } else if (typeof keys === 'boolean') {
    return false
  } else if (typeof keys === 'object') {
    if (JSON.stringify(keys) === '{}') {
      return true
    } else if (keys === null) { // null
      return true
    } else {
      return false
    }
  }

  if (keys instanceof Array && keys.length === 0) { // 数组
    return true
  }
}
/* eslint-enable */
/**
 * 深拷贝对象
 */
util.copyObject = function (object) {
  const copy = JSON.parse(JSON.stringify(object))
  return copy
}

/**
 * @description : .号多层字段解析
 * @author ： houjianhong
 * @argument : obj : 要读取对象
 *             key : 要读取的属性
 * @returns : 返回对应属性的值
 */
util.readObjectKey = function (obj, key) {
  const keys = key.split('.')
  let result = ''
  const newObj = util.copyObject(obj)// 深拷贝一份数据

  for (let i = 0; i < keys.length; i++) {
    if (i === 0) {
      if (!newObj) {
        result = ''
        return result
      } else {
        result = newObj[keys[i]]
      }
    } else {
      if (!result) {
        result = ''
        return result
      } else {
        result = result[keys[i]]
      }
    }
  }
  return result
}

/**
 * 深拷贝对象
 */
util.copyObject = function (object) {
  const copy = JSON.parse(JSON.stringify(object))
  return copy
}

/**
 * @module deleteEmptyProperty
 * @description 去掉对象中得空值
 * @param  {} data
*/
util.deleteEmptyProperty = (data) => {
  for (const key in data) {
    const value = data[key]
    if (value instanceof Object) {
      if (JSON.stringify(value) === '{}') {
        //delete data[key]
        continue
      }
      if (value instanceof Array) {
        if (value.length === 0) {
          delete data[key]
          continue
        } else {
          util.deleteEmptyProperty(data[key])
        }
      } else {
        if (util.isEmpty(value)) {
          delete data[key]
        } else {
          util.deleteEmptyProperty(data[key])
        }
      }
    } else {
      if (value === '' || value === null || value === undefined) {
        delete data[key]
      }
    }
  }
  return data
}

/**
 *
 * @description 设置对象的值
 *
 * @auth  houjianhong
 *
 */
util.setObjectValue = function (obj, valueObject) {
  const getType = Object.prototype.toString
  const copyValueObject = util.copyObject(valueObject)
  for (const item in obj) {
    const value = obj[item]
    if (!copyValueObject[item] && copyValueObject[item] !== 0) {
      continue
    }
    switch (getType.call(value)) {
      case '[object Object]': util.setObjectValue(value, copyValueObject[item]); break
      case '[object Array]': obj[item] = copyValueObject[item]; break
      case '[object Function]': break
      default: obj[item] = copyValueObject[item]; break
    }
  }
}

/**
 *
 * @description 清空对象的value值
 *
 * @author houjianhong
 *
 */
util.clearObjectValue = function (obj) {
  const getType = Object.prototype.toString
  for (const item in obj) {
    const value = obj[item]
    switch (getType.call(value)) {
      case '[object Object]': util.clearObjectValue(value); break
      case '[object Array]': obj[item] = []; break
      case '[object Null]': break
      case '[object Function]': break
      default: obj[item] = ''; break
    }
  }
}

/**
 * @description  value: 翻译值
 *               list : key value 数组
 *
 * @return name：key的值
 */
util.translate = function (value, list) {
  for (let i = 0; i < list.length; i++) {
    if (value === list[i].value) {
      return list[i].name
    }
  }
  return ''
}

/**
 * 获取uuid
 */
util.uuid = function () {
  var s = []
  var hexDigits = '0123456789abcdef'
  // var hexDigits = 'abcdefghijklmnokqrst'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '1'
  var uuid = s.join('')
  return uuid
}

// 发文模块获取uuid
util.uuidMyself = function () {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 32; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '1'
  var uuidMyself = s.join('')
  return uuidMyself
}

// download下载
util.download = function (res, name) {
  const blob = new Blob([res])
  if ('download' in document.createElement('a')) {
    // 非IE下载
    const link = document.createElement('a')
    console.log(res)
    console.log(blob)
    link.download = name
    link.style.display = 'none'
    link.href = URL.createObjectURL(blob)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href) // 释放URL 对象
    document.body.removeChild(link)
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob)
  }
}
util.enumDataAdapter = function (enumData, prop) {
  const data = []
  for (const item in enumData) {
    const enumObejct = {}
    enumObejct[prop.key] = enumData[item]
    enumObejct[prop.value] = isNaN(Number(item)) ? item : Number(item)
    data.push(enumObejct)
  }
  return data
}
/**
 *  @description 获取日期格式根据传入的yyyy-MM-dd hh:mm:ss获取对应的时间
 *
 *  @argument date : 要格式化的时间 类型可以为Date、时间戳、时间格式字符串
 *            str ： 要格式化的格式 yyyy-MM-dd hh:mm:ss
 */
util.formatDateByArg = function (date, str) {
  let formateDate = '';
  if (date) {
    formateDate = new Date(date);
  } else {
    formateDate = new Date();
  }
  var month = formateDate.getMonth() + 1 > 9 ? formateDate.getMonth() + 1 : "0" + (formateDate.getMonth() + 1);
  var day = formateDate.getDate() > 9 ? formateDate.getDate() : "0" + formateDate.getDate();
  var hour = formateDate.getHours() < 10 ? "0" + formateDate.getHours() : formateDate.getHours();
  var minutes = formateDate.getMinutes() < 10 ? "0" + formateDate.getMinutes() : formateDate.getMinutes();
  var seconds = formateDate.getSeconds() < 10 ? "0" + formateDate.getSeconds() : formateDate.getSeconds();
  str = str.replace("yyyy", formateDate.getFullYear());
  str = str.replace("MM", month);
  str = str.replace("dd", day);
  str = str.replace("hh", hour);
  str = str.replace("mm", minutes);
  str = str.replace("ss", seconds);
  return str;
}

util.Base64ToFile = (img) => {
  let binary = atob(img.split(',')[1]);
  let mime = img.split(',')[0].match(/:(.*?);/)[1];
  let array = [];
  for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  let fileData = new Blob([new Uint8Array(array)], {
      type: mime,
  });
  let file = new File([fileData], `${new Date().getTime()}.png`, { type: mime });
  return file;
}

/**
 * 计算开始时间与结束时间的时间差
 * @param {*} startDate  开始时间
 * @param {*} endDate 结束时间
 * @returns
 */
util.diffTime = (startDate,endDate)=>{
  startDate= new Date(startDate);
  endDate = new Date(endDate);
  var diff=endDate.getTime() - startDate.getTime();//时间差的毫秒数

  //计算出相差天数
  var days=Math.floor(diff/(24*3600*1000));

  //计算出小时数
  var leave1=diff%(24*3600*1000);    //计算天数后剩余的毫秒数
  var hours=Math.floor(leave1/(3600*1000));
  //计算相差分钟数
  var leave2=leave1%(3600*1000);        //计算小时数后剩余的毫秒数
  var minutes=Math.floor(leave2/(60*1000));

  //计算相差秒数
  var leave3=leave2%(60*1000);      //计算分钟数后剩余的毫秒数
  var seconds=Math.round(leave3/1000);

  var returnStr = seconds + "秒";
  if(minutes>0) {
      returnStr = minutes + "分" + returnStr;
  }
  if(hours>0) {
      returnStr = hours + "小时" + returnStr;
  }
  if(days>0) {
      returnStr = days + "天" + returnStr;
  }
  return {
    days,
    hours,
    minutes,
    seconds,
    str:returnStr
  };
}

util.Base64ToFile = (img) => {
  let binary = atob(img.split(',')[1]);
  let mime = img.split(',')[0].match(/:(.*?);/)[1];
  let array = [];
  for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  let fileData = new Blob([new Uint8Array(array)], {
      type: mime,
  });
  let file = new File([fileData], `${new Date().getTime()}.png`, { type: mime });
  return file;
}

// 判断字符串是否符合JSON格式
util.isJSONStr = (str) => {
  if (typeof str === 'string') {
    try {
      var obj = JSON.parse(str)
      return typeof obj === 'object' && obj 
    } catch (e) {
      return false
    }
  }else {
    return false
  }
}

/**
 * 实体类参数与数据库字段转换
 * @param {*} data 参数
 * @param {*} type 1实体类转数据库    2数据库转实体类
 * @returns
 */
util.transferEntity = (data, type) => {
  let obj = {}
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key]
      if (type === '1') {
        let newKey = humpToUnderline(key)
        newKey = newKey.toUpperCase()
        obj[newKey] = element
      } else if (type === '2') {
        const newKey = underlineToHump(key.toLowerCase())
        obj[newKey] = element
      }
    }
  }
  return obj
}

// 下划线转驼峰
function underlineToHump(s) {
  var a = s.split("_");
  var result = a[0];
  for(var i=1;i<a.length;i++){
    result = result + a[i].slice(0,1).toUpperCase() + a[i].slice(1);
  }
  return result
}
util.underlineToHump = underlineToHump

// 驼峰转下划线
function humpToUnderline(str) {
  return str.replace(/([A-Z])/g,"_$1").toLowerCase()
}
util.humpToUnderline = humpToUnderline

// 深拷贝
util.deepClone = function (obj) {
  //判断拷贝的要进行深拷贝的是数组还是对象，是数组的话进行数组拷贝，对象的话进行对象拷贝
  var objClone = Array.isArray(obj) ? [] : {};
  //进行深拷贝的不能为空，并且是对象或者是
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = util.deepClone(obj[key]);
        } else {
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}


function safe_add(x, y) {
  var lsw = (x & 0xFFFF) + (y & 0xFFFF),
      msw = (x >> 16) + (y >> 16) + (lsw >> 16);
  return (msw << 16) | (lsw & 0xFFFF);
}

/*
* Bitwise rotate a 32-bit number to the left.
*/
function bit_rol(num, cnt) {
  return (num << cnt) | (num >>> (32 - cnt));
}

/*
* These functions implement the four basic operations the algorithm uses.
*/
function md5_cmn(q, a, b, x, s, t) {
  return safe_add(bit_rol(safe_add(safe_add(a, q), safe_add(x, t)), s), b);
}
function md5_ff(a, b, c, d, x, s, t) {
  return md5_cmn((b & c) | ((~b) & d), a, b, x, s, t);
}
function md5_gg(a, b, c, d, x, s, t) {
  return md5_cmn((b & d) | (c & (~d)), a, b, x, s, t);
}
function md5_hh(a, b, c, d, x, s, t) {
  return md5_cmn(b ^ c ^ d, a, b, x, s, t);
}
function md5_ii(a, b, c, d, x, s, t) {
  return md5_cmn(c ^ (b | (~d)), a, b, x, s, t);
}

/*
* Calculate the MD5 of an array of little-endian words, and a bit length.
*/
function binl_md5(x, len) {
  /* append padding */
  x[len >> 5] |= 0x80 << (len % 32);
  x[(((len + 64) >>> 9) << 4) + 14] = len;

  var i, olda, oldb, oldc, oldd,
      a = 1732584193,
      b = -271733879,
      c = -1732584194,
      d = 271733878;

  for (i = 0; i < x.length; i += 16) {
    olda = a;
    oldb = b;
    oldc = c;
    oldd = d;

    a = md5_ff(a, b, c, d, x[i], 7, -680876936);
    d = md5_ff(d, a, b, c, x[i + 1], 12, -389564586);
    c = md5_ff(c, d, a, b, x[i + 2], 17, 606105819);
    b = md5_ff(b, c, d, a, x[i + 3], 22, -1044525330);
    a = md5_ff(a, b, c, d, x[i + 4], 7, -176418897);
    d = md5_ff(d, a, b, c, x[i + 5], 12, 1200080426);
    c = md5_ff(c, d, a, b, x[i + 6], 17, -1473231341);
    b = md5_ff(b, c, d, a, x[i + 7], 22, -45705983);
    a = md5_ff(a, b, c, d, x[i + 8], 7, 1770035416);
    d = md5_ff(d, a, b, c, x[i + 9], 12, -1958414417);
    c = md5_ff(c, d, a, b, x[i + 10], 17, -42063);
    b = md5_ff(b, c, d, a, x[i + 11], 22, -1990404162);
    a = md5_ff(a, b, c, d, x[i + 12], 7, 1804603682);
    d = md5_ff(d, a, b, c, x[i + 13], 12, -40341101);
    c = md5_ff(c, d, a, b, x[i + 14], 17, -1502002290);
    b = md5_ff(b, c, d, a, x[i + 15], 22, 1236535329);

    a = md5_gg(a, b, c, d, x[i + 1], 5, -165796510);
    d = md5_gg(d, a, b, c, x[i + 6], 9, -1069501632);
    c = md5_gg(c, d, a, b, x[i + 11], 14, 643717713);
    b = md5_gg(b, c, d, a, x[i], 20, -373897302);
    a = md5_gg(a, b, c, d, x[i + 5], 5, -701558691);
    d = md5_gg(d, a, b, c, x[i + 10], 9, 38016083);
    c = md5_gg(c, d, a, b, x[i + 15], 14, -660478335);
    b = md5_gg(b, c, d, a, x[i + 4], 20, -405537848);
    a = md5_gg(a, b, c, d, x[i + 9], 5, 568446438);
    d = md5_gg(d, a, b, c, x[i + 14], 9, -1019803690);
    c = md5_gg(c, d, a, b, x[i + 3], 14, -187363961);
    b = md5_gg(b, c, d, a, x[i + 8], 20, 1163531501);
    a = md5_gg(a, b, c, d, x[i + 13], 5, -1444681467);
    d = md5_gg(d, a, b, c, x[i + 2], 9, -51403784);
    c = md5_gg(c, d, a, b, x[i + 7], 14, 1735328473);
    b = md5_gg(b, c, d, a, x[i + 12], 20, -1926607734);

    a = md5_hh(a, b, c, d, x[i + 5], 4, -378558);
    d = md5_hh(d, a, b, c, x[i + 8], 11, -2022574463);
    c = md5_hh(c, d, a, b, x[i + 11], 16, 1839030562);
    b = md5_hh(b, c, d, a, x[i + 14], 23, -35309556);
    a = md5_hh(a, b, c, d, x[i + 1], 4, -1530992060);
    d = md5_hh(d, a, b, c, x[i + 4], 11, 1272893353);
    c = md5_hh(c, d, a, b, x[i + 7], 16, -155497632);
    b = md5_hh(b, c, d, a, x[i + 10], 23, -1094730640);
    a = md5_hh(a, b, c, d, x[i + 13], 4, 681279174);
    d = md5_hh(d, a, b, c, x[i], 11, -358537222);
    c = md5_hh(c, d, a, b, x[i + 3], 16, -722521979);
    b = md5_hh(b, c, d, a, x[i + 6], 23, 76029189);
    a = md5_hh(a, b, c, d, x[i + 9], 4, -640364487);
    d = md5_hh(d, a, b, c, x[i + 12], 11, -421815835);
    c = md5_hh(c, d, a, b, x[i + 15], 16, 530742520);
    b = md5_hh(b, c, d, a, x[i + 2], 23, -995338651);

    a = md5_ii(a, b, c, d, x[i], 6, -198630844);
    d = md5_ii(d, a, b, c, x[i + 7], 10, 1126891415);
    c = md5_ii(c, d, a, b, x[i + 14], 15, -1416354905);
    b = md5_ii(b, c, d, a, x[i + 5], 21, -57434055);
    a = md5_ii(a, b, c, d, x[i + 12], 6, 1700485571);
    d = md5_ii(d, a, b, c, x[i + 3], 10, -1894986606);
    c = md5_ii(c, d, a, b, x[i + 10], 15, -1051523);
    b = md5_ii(b, c, d, a, x[i + 1], 21, -2054922799);
    a = md5_ii(a, b, c, d, x[i + 8], 6, 1873313359);
    d = md5_ii(d, a, b, c, x[i + 15], 10, -30611744);
    c = md5_ii(c, d, a, b, x[i + 6], 15, -1560198380);
    b = md5_ii(b, c, d, a, x[i + 13], 21, 1309151649);
    a = md5_ii(a, b, c, d, x[i + 4], 6, -145523070);
    d = md5_ii(d, a, b, c, x[i + 11], 10, -1120210379);
    c = md5_ii(c, d, a, b, x[i + 2], 15, 718787259);
    b = md5_ii(b, c, d, a, x[i + 9], 21, -343485551);

    a = safe_add(a, olda);
    b = safe_add(b, oldb);
    c = safe_add(c, oldc);
    d = safe_add(d, oldd);
  }
  return [a, b, c, d];
}

/*
* Convert an array of little-endian words to a string
*/
function binl2rstr(input) {
  var i,
      output = '';
  for (i = 0; i < input.length * 32; i += 8) {
    output += String.fromCharCode((input[i >> 5] >>> (i % 32)) & 0xFF);
  }
  return output;
}

/*
* Convert a raw string to an array of little-endian words
* Characters >255 have their high-byte silently ignored.
*/
function rstr2binl(input) {
  var i,
      output = [];
  output[(input.length >> 2) - 1] = undefined;
  for (i = 0; i < output.length; i += 1) {
    output[i] = 0;
  }
  for (i = 0; i < input.length * 8; i += 8) {
    output[i >> 5] |= (input.charCodeAt(i / 8) & 0xFF) << (i % 32);
  }
  return output;
}

/*
* Calculate the MD5 of a raw string
*/
function rstr_md5(s) {
  return binl2rstr(binl_md5(rstr2binl(s), s.length * 8));
}

/*
* Calculate the HMAC-MD5, of a key and some data (raw strings)
*/
function rstr_hmac_md5(key, data) {
  var i,
      bkey = rstr2binl(key),
      ipad = [],
      opad = [],
      hash;
  ipad[15] = opad[15] = undefined;
  if (bkey.length > 16) {
    bkey = binl_md5(bkey, key.length * 8);
  }
  for (i = 0; i < 16; i += 1) {
    ipad[i] = bkey[i] ^ 0x36363636;
    opad[i] = bkey[i] ^ 0x5C5C5C5C;
  }
  hash = binl_md5(ipad.concat(rstr2binl(data)), 512 + data.length * 8);
  return binl2rstr(binl_md5(opad.concat(hash), 512 + 128));
}

/*
* Convert a raw string to a hex string
*/
function rstr2hex(input) {
  var hex_tab = '0123456789abcdef',
      output = '',
      x,
      i;
  for (i = 0; i < input.length; i += 1) {
    x = input.charCodeAt(i);
    output += hex_tab.charAt((x >>> 4) & 0x0F) +
        hex_tab.charAt(x & 0x0F);
  }
  return output;
}

/*
* Encode a string as utf-8
*/
function str2rstr_utf8(input) {
  return unescape(encodeURIComponent(input));
}

/*
* Take string arguments and return either raw or hex encoded strings
*/
function raw_md5(s) {
  return rstr_md5(str2rstr_utf8(s));
}
function hex_md5(s) {
  return rstr2hex(raw_md5(s));
}
function raw_hmac_md5(k, d) {
  return rstr_hmac_md5(str2rstr_utf8(k), str2rstr_utf8(d));
}
function hex_hmac_md5(k, d) {
  return rstr2hex(raw_hmac_md5(k, d));
}

export const md5 = (string, key, raw) => {
  if (!key) {
    if (!raw) {
      return hex_md5(string);
    }
    return raw_md5(string);
  }
  if (!raw) {
    return hex_hmac_md5(key, string);
  }
  return raw_hmac_md5(key, string);
}

// 自定义比较函数 机台排序
export const compareChineseNumeric = (a, b) => {
  const pattern = /[\u4e00-\u9fa5]+|[0-9]+/g; // 匹配中文和数字的正则表达式

  // 提取中文和数字
  const aParts = a.deviceName.match(pattern);
  const bParts = b.deviceName.match(pattern);

  // 判断是否有数字部分
  const aHasNumber = aParts.some(part => !isNaN(parseInt(part)));
  const bHasNumber = bParts.some(part => !isNaN(parseInt(part)));

  if (!aHasNumber && !bHasNumber) {
    return 0; // 两者都没有数字部分，位置不变
  } else if (!aHasNumber) {
    return -1; // a 没有数字部分，排在前面
  } else if (!bHasNumber) {
    return 1; // b 没有数字部分，排在前面
  } else {
    // 两者都有数字部分，比较中文和数字部分
    const aChinese = aParts.filter(part => isNaN(parseInt(part))).join('');
    const bChinese = bParts.filter(part => isNaN(parseInt(part))).join('');
    const chineseComparison = aChinese.localeCompare(bChinese);

    if (chineseComparison !== 0) {
      return chineseComparison; // 如果中文部分不相等，则直接返回比较结果
    } else {
      // 如果中文部分相等，则比较数字部分（将字符串转换为数字进行比较）
      const aNumbers = aParts.filter(part => !isNaN(parseInt(part))).map(part => parseInt(part));
      const bNumbers = bParts.filter(part => !isNaN(parseInt(part))).map(part => parseInt(part));

      // 比较数字部分
      const minLength = Math.min(aNumbers.length, bNumbers.length);
      for (let i = 0; i < minLength; i++) {
        if (aNumbers[i] !== bNumbers[i]) {
          return aNumbers[i] - bNumbers[i];
        }
      }

      // 如果前面的数字部分相等，则较长的数字部分更大
      return aNumbers.length - bNumbers.length;
    }
  }
}

//网关验证方法判断是否正常s
util.pingNet = (ip) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    var start = new Date().getTime();
    img.src = "http://" + ip + "?t=" + start;
    let flag = false;
    img.onload = function () {
      flag = true;
      resolve(flag);
    }
    img.onerror = function () {
      flag = true;
      resolve(flag);
    };

    var timer = setTimeout(function () {
      if (!flag) {
        flag = false;
        resolve(flag);
      }
    }, 1500);
  });
}

export const string2Unit8Array = (str) => {
  str = window.btoa(str)
  let arr = []
  for(let i=0, j=str.length; i < j; ++i) {
    arr.push(str.charCodeAt(i))
  }

  let temUint8Array = new Uint8Array(arr)
  return temUint8Array
}

util.formatByte2Img = (data) => {

    let blobUrl = ''
    // const bytes = new Uint8Array(data)
    const bytes = string2Unit8Array(data)
    const blob = new Blob([bytes], { type: 'image/png' })
    blobUrl = (window.URL || window.webkitURL).createObjectURL(blob)
    return blobUrl

}

/**
 * @param {string} url
 * @returns {Object}
 */
util.getQueryObject = (url) =>  {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

util.sleep = (time) => {
  return new Promise(function(resolve){
    setTimeout(resolve, time);
  });
}

util.base64toFile = (dataurl, filename = 'file') =>{
  let arr = dataurl.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  // suffix是该文件的后缀
  let suffix = mime.split('/')[1];
  // atob 对经过 base-64 编码的字符串进行解码
  let bstr = atob(arr[1]);
  // n 是解码后的长度
  let n = bstr.length;
  // Uint8Array 数组类型表示一个 8 位无符号整型数组 初始值都是 数子0
  let u8arr = new Uint8Array(n);
  // charCodeAt() 方法可返回指定位置的字符的 Unicode 编码。这个返回值是 0 - 65535 之间的整数
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  // new File返回File对象 第一个参数是 ArraryBuffer 或 Bolb 或Arrary 第二个参数是文件名
  // 第三个参数是 要放到文件中的内容的 MIME 类型
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  });
}
util.downloadImage = (url) => {
  let image = new Image()
  image.setAttribute("crossOrigin", "anonymous")
  image.src = url
  image.onload = () => {
    let canvas = document.createElement("canvas")
    canvas.width = image.width
    canvas.height = image.height
    let ctx = canvas.getContext("2d")
    ctx.drawImage(image, 0, 0, image.width, image.height)
    canvas.toBlob((blob) => {
      let url = URL.createObjectURL(blob)
      let Link = document.createElement("a")
      Link.download = util.uuid()
      Link.href = url
      Link.click()
      Link.remove()
      // 用完释放URL对象
      URL.revokeObjectURL(url)
    });
  }
}


export default util
