<!--
  文件描述：
  创建时间：2023/8/8 16:49
  创建人：huanghengping
-->
<template>
  <el-dialog
    :append-to-body="true"
    :show-close="false"
    :visible="show"
    class="LatexInputDialog"
    custom-class="type-dict-cls"
    top="4vh"
    width="1000"
  >
    <template slot="title">
      <div class="title">
        <div>公式</div>
      </div>
    </template>

    <div class="content">
      <div id="kfEditorContainer" class="kf-editor">
        <div id="tips" class="tips">
          sorry! 版本仅支持IE9及以上版本的浏览器！
        </div>
      </div>
      <div class="btn-group">
        <div class="btn" @click="confirm">确认</div>
        <div class="btn-gray" @click="reject(false);show = false;">取消</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
// 例如：import uploadFile from '@/components/uploadFile/uploadFile'

import '@/assets/styles/base.css'
import '@/assets/styles/ui.css'
import '@/assets/styles/scrollbar.css'
import {Message} from "element-ui";

//处理TinyMCE编辑器的事件
window.addEventListener('message', function (event) {
  var data = event.data;
  if(typeof window.kfe == 'undefined' || data != 'save') return;
  window.kfe.execCommand('get.image.data', function(data){
    var latex = kfe.execCommand('get.source');
    window.parent.postMessage({
      mceAction: 'insertContent',
      content: "<img src=\""+data.img+"\" data-latex=\""+latex+"\">"
    }, '*');
    window.parent.postMessage({
      mceAction: 'close'
    }, '*');
  });
});

export default {
  name: "LatexInputDialog",
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    // patientIn: {
    //   type: Object,
    //   default () {
    //     return {}
    //   }
    // }
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    // 这里存放数据
    return {
      selectItem: null,
      resolve: null,
      reject: null,
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 方法集合
  methods: {
    open(item) {
      this.selectItem = item
      if(item.latex) {
        setTimeout(()=> {
          window.kfe.execCommand( "render",decodeURIComponent(item.latex))
        },500)
      }else {
        window.kfe?.execCommand( "render","\\placeholder" )
      }
      this.show = true;
      return  new Promise((resolve, reject) => { // 这里只使用了resolve,是因为上边使用的await接受结果，没法直接获取到reject的结果
        this.resolve = resolve
        this.reject = reject
      })
    },
    confirm() {
      let latex = kfe.execCommand('get.source');
      if(latex == '\placeholder ' || latex == '\\placeholder ' || !latex) {
        Message.warning({ message: '请输入公式',customClass:'big-message'})
        return
      }

      this.selectItem.value = latex
      // this.$emit('confirm', latex)
      this.show = false
      this.resolve(latex)
    },
  },
  // 监控data中的数据变化
  watch: {
    show: {
      handler(newVal) {
        if(newVal) {

          //实例化编辑器
          jQuery( function ($) {
            if ( document.body.addEventListener ) {

              $( "#tips").html('<div id="loading"><img src="kityformula/loading.gif" alt="loading" /><p>正在加载，请耐心等待...</p></div>' );

              var factory = kf.EditorFactory.create( $( "#kfEditorContainer" )[ 0 ], {
                render: {
                  fontsize: 24
                },
                resource: {
                  path: "./kityformula/resource/"
                }
              } );
              factory.ready( function ( KFEditor ) {
                $( "#tips").remove();
                //处理地址栏的参数并加载到编辑器中
                // var c=getQueryVariable("c")
                if(window.latex){
                  KFEditor.execCommand( "render",decodeURIComponent(window.latex))
                  window.latex = null

                }else{
                  KFEditor.execCommand( "render","\\placeholder" )
                };

                this.execCommand( "focus" );
                window.kfe = KFEditor;
              } );
            } else {
              $( "#tips").css( "color", "black" );
              $( "#tips").css( "padding", "10px" );
            }
          } );
        }
      },
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {
  }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
//@import url(); 引入公共css类
.LatexInputDialog {
  height: 100%;
  width: 100%;

  .title {
    width: 100%;
    padding-top: 24px;
    font-weight: bold;
    font-size: 24px;
    color: white;
    position: relative;
    text-align: center;

    .el-icon-circle-close {
      color: white;
      width: 30px;
      height: 30px;
      cursor: pointer;
      font-size: 30px;
      right: 24px;
      position: absolute;
    }
  }

  .content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;

    .btn-group {
      display: flex;
      flex: 1;
      align-items: flex-end;
      column-gap: 20px;
      justify-content: space-evenly;

      .btn {
        height: 50px !important;
        width: 180px;
        margin-top: 24px;
        background: #3054ed;
        border-radius: 8px;
        height: 24px;
        font-size: 18px;
        color: white;
        line-height: 50px;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: #3350be;
        }
      }

      .btn-gray {
        background-color: #aaa8a8 !important;
        cursor: pointer !important;
        color: #27375e !important;

        width: 180px;
        height: 50px !important;
        margin-top: 24px;
        border-radius: 8px;
        height: 24px;
        font-size: 18px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;

        &:hover {
          background-color: #aaa8a8 !important;
        }
      }
    }

    .kf-editor {
      width: 90%;
      margin: 0 auto;
      height: 600px;
      border-radius: 8px;
    }

    #loading {
      height: 32px;
      width: 340px;
      line-height: 32px;
      position: absolute;
      top: 72%;
      left: 50%;
      margin-left: -170px;
      font-family: arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
    }
    #loading img {
      position: absolute;
    }
    #loading p {
      display: block;
      position: absolute;
      left: 40px;
      top: 0px;
      margin: 0;
    }


    .kf-editor { background: #fff}
    .kf-editor-ui-latex-area { display: none; }
    .kf-editor-ui-canvas { width: 100%; }
    .kf-editor > .fui-container:first-child { background: #efefef; }

  }
}
</style>
