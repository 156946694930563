<template>
  <div id="app">
    <keep-alive :include="keepAlivePage">
      <router-view ></router-view>
    </keep-alive>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  components: {

  },
  computed:{
    ...mapState({
      currentEnv:state=> state.comm.currentEnv,
      keepAlivePage:'keepAlivePage'
    })
  },
  methods: {
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    if (this.globalVariable.isMathjaxConfig) {
      // 判断是否初始配置，若⽆则配置。
      this.globalVariable.initMathjaxConfig()
    }
    this.globalVariable.TypeSet()
  },
  updated() {
    if (this.globalVariable.isMathjaxConfig) {
      // 判断是否初始配置，若⽆则配置。
      this.globalVariable.initMathjaxConfig()
    }
    this.globalVariable.TypeSet()
  },

}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
