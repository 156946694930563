import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style/index.scss'
import '@/assets/style/comm.css'
import  '@/common/rem.js'

import http from '@/common/http'
import api from '@/api/api'

import 'amfe-flexible'
import 'amfe-flexible/index.js'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)


import LoginDialog from '@/components/LoginDialog'
const loginDialog = new Vue(LoginDialog)
loginDialog.$mount(document.createElement('div'))
document.body.appendChild(loginDialog.$el)
Vue.prototype.$loginDialog = loginDialog

import LatexInputDialog from '@/components/LatexInputDialog'
const latexInputDialog = new Vue(LatexInputDialog)
latexInputDialog.$mount(document.createElement('div'))
document.body.appendChild(latexInputDialog.$el)
Vue.prototype.$latexInputDialog = latexInputDialog


import KnowledgeAI from '@/components/KnowledgeAI'
const knowledgeAI = new Vue(KnowledgeAI)
knowledgeAI.$mount(document.createElement('div'))
document.body.appendChild(knowledgeAI.$el)
Vue.prototype.$knowledgeAI = knowledgeAI

Vue.config.productionTip = false

Vue.prototype.$api = api
Vue.prototype.$http = http

import globalVariable from '@/common/globalVariable.js'
Vue.prototype.globalVariable = globalVariable

import jsCookie from 'js-cookie'
Vue.prototype.$cookie = jsCookie


import VueQuillEditor from 'vue-quill-editor'

// 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor, /* { 默认全局 } */)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

//解决重复点击报错
const originalPush = router.push
router.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


