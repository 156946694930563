<template>
  <!-- 登录弹出框 -->
  <el-dialog
      :visible.sync="loginVisible"
      :show-close="false"
      custom-class="login-dialog">
    <template slot="title"></template>
    <div class="top">
      <i class="el-icon-circle-close" @click="loginVisible=false"></i>
    </div>
    <div class="title">登录机械知识GPT账号</div>

    <el-form :model="loginData">
      <el-form-item label="账号" label-width="50">
        <el-input v-model="loginData.username" style="width: 300px;"></el-input>
      </el-form-item>
      <el-form-item label="密码" label-width="50">
        <el-input type="password" v-model="loginData.password" style="width: 300px;"></el-input>
      </el-form-item>
    </el-form>

    <div class="btn-group" style="flex-direction: column;row-gap: 10px;align-items: center">
      <div class="btn" @click="login">登录</div>
      <div class="btn" @click="loginAuth2">统一用户登录</div>
    </div>

    <!-- 确定、取消按钮 -->
    <span slot="footer" class="dialog-footer">
      </span>
  </el-dialog>
</template>

<script>
import smCrypto from "@/common/smCrypto";
import api from "@/api/api";

export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      loginVisible: false,
      loginData: {
        // username: 'superAdmin',
        // password: '123456'
        username: '',
        password: ''
      },
    }
  },
  //计算属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    isLoginAndOpen() {
      if(!localStorage.getItem('token')) {
        this.loginVisible = true
        return false
      }
      return true
    },
    open() {
      this.loginVisible = true
    },
    loginAuth2() {
      window.location = `/api/oauth2/loginFront`
    },
    async login() {
      const loginData = {
        account: this.loginData.username,
        // 密码进行SM2加密，传输过程中看到的只有密文，后端存储使用hash
        password: smCrypto.doSm2Encrypt(this.loginData.password),
      }

      let {data} = await this.$http(api.doLogin, loginData)
      if (data) {
        localStorage.setItem('token', data)
        const { data : userData } = await this.$http(api.getUserInfo)
        localStorage.setItem('userInfo', JSON.stringify(userData))
        // this.$store.state.userInfo = userData
        this.loginVisible = false
        location.reload()
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  //生命周期 - 创建之前
  beforeCreate() {
  },
  //生命周期 - 挂载之前
  beforeMount() {
  },
  //生命周期 - 更新之前
  beforeUpdate() {
  },
  //生命周期 - 更新之后
  updated() {
  },
  //生命周期 - 销毁之前
  beforeDestroy() {
  },
  //生命周期 - 销毁完成
  destroyed() {
  },
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {
  }
};
</script>

<style scoped>

</style>
