

// const BASE_URL = 'http://192.168.1.195:8080'
const BASE_URL = '/api'
// const BASE_AI_URL = 'http://112.111.39.218:30000'
// const BASE_AI_URL = 'http://192.168.0.181:30000'
const BASE_AI_URL = '/aiapi'
// const BASE_AUTH_URL = "/basicapi";
const BASE_AUTH_URL = process.env.VUE_APP_BASIC_API

// const BASE_AI_URL =''
const api = {
  getImageUrl: (path, scale = 1) => {
    return `${BASE_AI_URL}/ai/lace/get-image?path=${path}&scale=${scale}`
  },
  uploadUrl: BASE_URL + '/dev/file/uploadMinioReturnUrl',
  uploadMinioReturnRelativeUrl: BASE_URL + '/dev/file/uploadMinioReturnRelativeUrl',
  getLoginUrl: {
    name: '获取登录',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/getLoginUrl',
    isAuthorize: true, //白名单
    showLoadingName: '登录中',
    openLoading: true
  },
  getLocalUserByIamUser: {
    name: '获取登录token信息',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/getLocalUserByIamUser',
    isAuthorize: true, //白名单
    showLoadingName: '登录中',
    openLoading: true
  },

  getDictData: {
    name: '获取类型',
    author: 'hhp',
    method: 'post',
    url: BASE_URL + '/system/dict/data/getDictData',
    isAuthorize: true //白名单
  },
  getNotice: {
    name: '获取公告列表',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/system/notice/list',
    isAuthorize: true //白名单
  },
  getBanner: {
    name: '获取banner',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/dev/config/page',
    isAuthorize: true //白名单
  },

  saveAIImage: {
    name: '保存图片历史',
    author: 'hhp',
    method: 'post',
    url: BASE_URL + '/ai/image',
  },
  doLogin: {
    name: '登录',
    author: 'hhp',
    method: 'post',
    url: BASE_URL + '/auth/b/doLogin',
    showLoadingName: '登录中',
    openLoading: true
  },
  getUserInfo: {
    name: '获取用户信息',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/auth/b/getLoginUser',
    showLoadingName: '获取用户信息',
    openLoading: true
  },

  savaLatex: {
    name: '保存公式',
    author: 'hhp',
    method: 'post',
    url: BASE_URL + '/biz/formula/add',
    showLoadingName: '保存中',
    openLoading: true
  },
  editLatex: {
    name: '修改公式',
    author: 'hhp',
    method: 'post',
    url: BASE_URL + '/biz/formula/edit',
    showLoadingName: '修改中',
    openLoading: true
  },
  deleteLatex: {
    name: '删除公式',
    author: 'hhp',
    method: 'post',
    url: BASE_URL + '/biz/formula/delete',
    showLoadingName: '删除中',
    openLoading: true
  },
  getLatexPage: {
    name: '获取公式列表',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/biz/formula/page',
    showLoadingName: '加载中',
  },
  formulaFavour: {
    name: '收藏公式',
    author: 'hhp',
    method: 'post',
    url: BASE_URL + '/biz/formula/favour',
  },
  addCalculateHistory: {
    name: '添加历史记录',
    author: 'hhp',
    method: 'post',
    url: BASE_URL + '/biz/calculatehistroy/add',
  },
  getCalculateHistory: {
    name: '获取历史记录',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/biz/calculatehistroy/page',
  },
  getFormula: {
    name: '获取方案市场(要登录)',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/biz/formula/page',
  },
  getFormulaPublic: {
    name: '获取公式市场',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/biz/formula/public'
  },
  getFormulaPerson: {
    name: '获取数量',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/biz/formula/person'
  },
  getCategory: {
    name: '获取目录',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/biz/category/page'
  },
  getCategoryId: {
    name: '根据公式id获取目录',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/biz/formula/categoryId'
  },
  getCategoryTree: {
    name: '获取目录树',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/biz/category/tree'
  },
  getCategoryLatex: {
    name: '根据目录获取公式',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/biz/formula/category'
  },
  addBookmark: {
    name: '添加书签',
    author: 'hhp',
    method: 'post',
    url: BASE_URL + '/biz/category/bookmark/add'
  },
  deleteBookmark: {
    name: '删除书签',
    author: 'hhp',
    method: 'post',
    url: BASE_URL + '/biz/category/bookmark/delete'
  },
  getBookmark: {
    name: '书签列表',
    author: 'hhp',
    method: 'get',
    url: BASE_URL + '/biz/category/bookmark/list'
  },





// ================================== ai接口start===============================

  aiFormulaTrans: {
    name: 'AI计算',
    author: 'hhp',
    method: 'post',
    url: BASE_AI_URL + '/formulaTrans',
    isAuthorize: true, //白名单
    showLoadingName: '计算中',
    openLoading: true,
    noTip: true,
  },
  aiEquationEvalf: {
    name: 'AI计算',
    author: 'hhp',
    method: 'post',
    url: BASE_AI_URL + '/equationEvalf',
    isAuthorize: true, //白名单
    showLoadingName: '计算中',
    openLoading: true,
    noTip: true,
  },
  aiKnowledgePlugin: {
    name: 'AI回答',
    author: 'hhp',
    method: 'post',
    url: BASE_AI_URL + '/knowledgePlugin',
    isAuthorize: true, //白名单
    noTip: true,
  },


// ================================== ai接口end===============================
  addDesign: {
    name: "新增设计",
    author: "hhp",
    method: "post",
    url: BASE_URL + "/biz/designlibrary/add",
    openLoading: true,
  },

  // 调用基础能力平台
  getUserAuthorized: {
    name: "查询用户是否授权",
    method: "get",
    url: BASE_AUTH_URL + "/fdd/sign/preview",
    openLoading: false
  },
  editDesign: {
    name: "修改设计",
    author: "hhp",
    method: "post",
    url: BASE_URL + "/biz/designlibrary/edit",
    openLoading: true,
  },
  addMenu: {
    name: "新增目录",
    author: "hhp",
    method: "post",
    url: BASE_URL + "/biz/designmenu/add",
    openLoading: true,
  },
  editMenu: {
    name: "编辑目录",
    author: "hhp",
    method: "post",
    url: BASE_URL + "/biz/designmenu/edit",
    openLoading: true,
  },
  menuList: {
    name: "目录列表",
    author: "hhp",
    method: "get",
    url: BASE_URL + "/biz/designmenu/page",
  },
  deleteMenu: {
    name: "删除目录",
    author: "hhp",
    method: "post",
    url: BASE_URL + "/biz/designmenu/delete",
    openLoading: true,
  },
  getGalleryAllList: {
    name: "获取全部设计列表",
    author: "hhp",
    method: "get",
    url: BASE_URL + "/biz/designlibrary/page",
    openLoading: true,
  },
  getGalleryList: {
    name: "获取设计库",
    author: "hhp",
    method: "get",
    url: BASE_URL + "/biz/designlibrary/getDesignLibrary",
    openLoading: true,
  },
  deleteDesign: {
    name: "删除设计",
    author: "hhp",
    method: "post",
    url: BASE_URL + "/biz/designlibrary/delete",
    openLoading: true,
  },
  moveItem: {
    name: "移动",
    author: "hhp",
    method: "post",
    url: BASE_URL + "/biz/designmenu/move",
    openLoading: true,
  },

  // 知识产权
  // appID: 42713e8067c6935909c7e3c10bc104fb
  // sercret: dadb412de683b699eb292b3e238693a4
  knGetToken: {
    name: "获取Token",
    author: "hhp",
    method: "post",
    url: "/knapi/auth/b/external",
    isAuthorize: true, //白名单
    openLoading: false,
  },
  knUploadMinioReturnUrlAndHash: {
    name: "上传文件返回url和Hash值",
    author: "hhp",
    method: "post",
    url: "/knapi/dev/file/uploadMinioReturnUrlAndHash",
    isAuthorize: true, //白名单
    openLoading: false,
  },
  knSaveCertdataAuto: {
    name: "保存证书数据",
    author: "hhp",
    method: "post",
    url: "/knapi/biz/certdataAuto/save",
    isAuthorize: true, //白名单
    openLoading: false,
    isCertApplyComplete: true,
  },
  logout: {
    name: "退出",
    method: "post",
    url: BASE_URL + "/api/oauth2/logout?isIndex=true",
    showLoadingName: "退出中",
    openLoading: true
  },
}

export default api
