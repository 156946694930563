import axios from 'axios'
// import { Toast } from 'vant'
import { Message } from 'element-ui';
import qs from 'qs'
import util from './util'
import parseString from "@/common/parse-string";
// import parseString from './parse-string'

import { Loading } from 'element-ui';
import store from "@/store";
import loginDialog from "@/components/LoginDialog.vue";
import Vue from "vue";
import jsCookie from "js-cookie";
const server = axios.create({
  timeout: 60 * 1000
})
const tempMap = new Map()
let loadingInstance = {}
server.interceptors.request.use((config) => {
  if (config.openLoading) {
    let uuid = new Date().getTime()
    if (loadingInstance[uuid]) {
      uuid = uuid + '' + new Date().getTime()
    }
    config.uuid = uuid
    loadingInstance[uuid] = Loading.service({
      fullscreen: true,
      lock: true,
      text: config.loadingName ? config.loadingName : '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
      customClass: 'loading'
    })
  }

  if(config.loadingMessage) {
    Message.warning({ message: config.loadingMessage,customClass:'big-message'})
  }
  // 请求拦截，token赋值
  const tokenInfo = localStorage.getItem('token')
  const headers = config.headers

  headers['Content-Type'] =
    headers['Content-Type'] || 'application/json'

  // headers['Connection'] = 'close'

  let isAuthorize = config.isAuthorize

  // headers.Authorization = 'bearer ' + 'wannengToken'
  // isAuthorize,则接口为白名单，不添加token
  if (tokenInfo && !isAuthorize) {
    headers.Authorization = 'Bearer ' + tokenInfo
  }
  return config
})

server.interceptors.response.use(
  (response) => {
    // const headers = response.headers

    const token = getToken()
    if(response.config.isCertApplyComplete) {
      jsCookie.set('token', `${token}`)
    }
    if (token) {
      localStorage.setItem('token', token)
    }
    if (response.config.uuid && loadingInstance[response.config.uuid]) {
      // 取出loading对象进行关闭
      loadingInstance[response.config.uuid].close()
      delete loadingInstance[response.config.uuid]
    }

    if (response.data.code != 200 && !response.config.noTip) {
      if (response.data.code ==   401) {

        localStorage.setItem('token', '')
        localStorage.setItem('userInfo','')
        store.state.userInfo = {}

        Vue.prototype.$cookie.remove('token');
        Message.warning({ message: '登录已过期,请重新进行登录',customClass:'big-message'});
        window.location = `/api/oauth2/loginFront`
        // Vue.prototype.$loginDialog.open()
        return
      }
      if(response.data instanceof Array) {
        return response.data
      }

      Message.error({ message: response.data?.msg ||'系统未知错误', customClass:'big-message',duration: 3000})
      return response.data
    }
    return response.data
  },
  (error) => {
    console.error(error)
    if (error.config.uuid && loadingInstance[error.config.uuid]) {
      // 取出loading对象进行关闭
      loadingInstance[error.config.uuid].close()
      delete loadingInstance[error.config.uuid]
    }

    if (error && error.response) {

      switch (error.response.code) {
        case 401:

        case 400:
        case 403:
          Message.warning({ message: error.response?.msg ||'无权限',customClass:'big-message'});
          break
        case 404:
        case 408:
        case 500:
        case 501:
        case 502:
        case 503:
        case 504:
        case 505:
        default:
          Message.warning({ message: error.response?.msg ||'服务器异常',customClass:'big-message'});
          break
      }
    }else {
      Message.warning({ message: '网络异常,请稍后重试',customClass:'big-message'});
    }
    return Promise.reject(error)
  }
)
const request = {
  post(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      server
        .post(url, params, config)
        .then(
          (response) => {
            resolve(response)
          },
          (err) => {
            reject(err)
          }
        )
        .catch((error) => {
          reject(error)
        })
    })
  },
  postForm(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      server
        .post(url, qs.stringify(params), config)
        .then(
          (response) => {
            resolve(response)
          },
          (err) => {
            reject(err)
          }
        )
        .catch((error) => {
          reject(error)
        })
    })
  },
  get(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      url =
        url.indexOf('?') === -1
          ? `${url}?t=${+new Date().getTime().toString()}`
          : `${url}&t=${new Date().getTime().toString()}`
      server
        .get(url, {
          params: params,
          // paramsSerializer: (params) => {
          //   return qs.stringify(params, {
          //     arrayFormat: 'indices',
          //     allowDots: true
          //   })
          // },
          ...config
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      url =
        url.indexOf('?') === -1
          ? `${url}?t=${+new Date().getTime().toString()}`
          : `${url}&t=${new Date().getTime().toString()}`
      server
        .delete(
          url,
          {
            params: params,
            // paramsSerializer: (params) => {
            //   return qs.stringify(params, {
            //     arrayFormat: 'indices',
            //     allowDots: true
            //   })
            // }
          },
          config
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteBody(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      url =
        url.indexOf('?') === -1
          ? `${url}?t=${+new Date().getTime().toString()}`
          : `${url}&t=${new Date().getTime().toString()}`
      server
        .delete(url, {
          data: params,
          ...config
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  put(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      server
        .put(url, params, config)
        .then(
          (response) => {
            resolve(response)
          },
          (err) => {
            reject(err)
          }
        )
        .catch((error) => {
          reject(error)
        })
    })
  },
  putForm(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      server
        .put(url, qs.stringify(params), config)
        .then(
          (response) => {
            resolve(response)
          },
          (err) => {
            reject(err)
          }
        )
        .catch((error) => {
          reject(error)
        })
    })
  },
  patch(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      server
        .patch(url, params, config)
        .then(
          (response) => {
            resolve(response)
          },
          (err) => {
            reject(err)
          }
        )
        .catch((error) => {
          reject(error)
        })
    })
  }
}
function getToken() {
  return localStorage.getItem('token') || ''
}
/* eslint-disable */
function URLDeatail (url, param) {
  const keys = url.match(/[^{}]+(?=\})/g)
  if (keys) {
    for (let i = 0; i < keys.length; i++) {
      url = url.replace(/\{[^\)]*\}/, param[keys[i]])
      delete param[keys[i]]
    }
  }
  return url
}
/* eslint-enable */
function http(api, params = {}, config = { headers: {} }, replaceUrl) {
  let newParam = null
  if (
    config.headers &&
    config.headers['Content-Type'] === 'multipart/form-data'
  ) {
    newParam = params
  } else {
    newParam = util.copyObject(params)
  }
  const newApi = util.copyObject(api)
  if (replaceUrl) {
    newApi.url = replaceUrl
  }
  if(!config.loadingMessage && api.loadingMessage) {
    config.loadingMessage = api.loadingMessage
  }
  if(api.openLoading) {
    config.openLoading = api.openLoading
    if(api.showLoadingName) {
      config.loadingName = api.showLoadingName
    }
  }
  config.isCertApplyComplete = api.isCertApplyComplete
  config.noTip = api.noTip
  newApi.url = parseString(newApi.url, newParam)
  //newApi.url = URLDeatail(newApi.url, newParam)
  // newParam = util.deleteEmptyProperty(newParam)


  //判断是否重复点击
  if(api.checkAgain) {
    let timestamp = new Date().getTime()
    let mapKey = api.url + JSON.stringify(params)
    if(tempMap.get(mapKey) ){
      const lastTime = tempMap.get(mapKey)
      if(lastTime && lastTime + 300*1000 > timestamp) {
        Message.warning({ message: '任务已在处理中,请勿重复操作',customClass:'big-message'});
        return
      }
    }
    // 遍历Map去除1小时前的数据
    for (let [key, value] of tempMap.entries()) {
      // 检查value是否是昨天的数据
      if (value < timestamp - 60 * 60 * 1000) {
        // 如果是昨天的数据，则从Map对象中删除该项
        tempMap.delete(key);
      }
    }
    tempMap.set(mapKey, timestamp)
  }



  return request[api.method](newApi.url, newParam, {
    ...config,
    isAuthorize: newApi.isAuthorize
  })
}
export default http
